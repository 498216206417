import React from "react"
import PropTypes from "prop-types"

import svg from "./sba-loan.svg"

const SbaLoan = ({ className }) => {
  return <img src={svg} className={className} alt="" />
}

SbaLoan.propTypes = {
  className: PropTypes.string,
}

export default SbaLoan
